// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---cache-igorko-redirect-js": () => import("/opt/build/repo/.cache/@igorko/redirect.js" /* webpackChunkName: "component---cache-igorko-redirect-js" */),
  "component---src-templates-news-list-js": () => import("/opt/build/repo/src/templates/newsList.js" /* webpackChunkName: "component---src-templates-news-list-js" */),
  "component---src-templates-news-js": () => import("/opt/build/repo/src/templates/news.js" /* webpackChunkName: "component---src-templates-news-js" */),
  "component---src-pages-404-js": () => import("/opt/build/repo/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-associations-js": () => import("/opt/build/repo/src/pages/associations.js" /* webpackChunkName: "component---src-pages-associations-js" */),
  "component---src-pages-contact-js": () => import("/opt/build/repo/src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cooperative-js": () => import("/opt/build/repo/src/pages/cooperative.js" /* webpackChunkName: "component---src-pages-cooperative-js" */),
  "component---src-pages-index-js": () => import("/opt/build/repo/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mctic-about-js": () => import("/opt/build/repo/src/pages/mctic-about.js" /* webpackChunkName: "component---src-pages-mctic-about-js" */),
  "component---src-pages-mctic-home-js": () => import("/opt/build/repo/src/pages/mctic-home.js" /* webpackChunkName: "component---src-pages-mctic-home-js" */),
  "component---src-pages-mctic-services-js": () => import("/opt/build/repo/src/pages/mctic-services.js" /* webpackChunkName: "component---src-pages-mctic-services-js" */),
  "component---src-pages-plan-js": () => import("/opt/build/repo/src/pages/plan.js" /* webpackChunkName: "component---src-pages-plan-js" */),
  "component---src-pages-projects-js": () => import("/opt/build/repo/src/pages/projects.js" /* webpackChunkName: "component---src-pages-projects-js" */),
  "component---src-pages-thanks-js": () => import("/opt/build/repo/src/pages/thanks.js" /* webpackChunkName: "component---src-pages-thanks-js" */)
}

